
import Lenis from 'lenis';
import { gsap } from 'gsap';

document.addEventListener('DOMContentLoaded', () => {
    const container = document.querySelector('.container-scrollx');
    const lenis = new Lenis({
        orientation: 'horizontal',
        content: container,
    });

    lenis.on('scroll', (e) => {
        container.scrollTo({
            top: 0,
            left: e.scrollX,
            behavior: 'smooth',
       
        });
    });

    // Handle vertical scrolling to switch sections
    document.addEventListener('wheel', (event) => {
        const delta = Math.sign(event.deltaY);
        if (delta !== 0) {
            const sectionWidth = window.innerWidth;
            const currentScrollLeft = container.scrollLeft;
            const sectionIndex = Math.floor(currentScrollLeft / sectionWidth);
            let targetScrollLeft;
            if (delta > 0) {
                targetScrollLeft = (sectionIndex + 1) * sectionWidth;
            } else {
                targetScrollLeft = (sectionIndex - 1) * sectionWidth;
            }

             // Clamp the target scroll left to ensure it doesn't go out of bounds
        targetScrollLeft = Math.max(0, Math.min(targetScrollLeft, container.scrollWidth - container.clientWidth));

           // Smooth scrolling animation using GSAP
           gsap.to(container, {
            scrollLeft: targetScrollLeft,
            duration: 0.3, // Adjust duration as needed
            ease: "slow(0.7, 0.7, false)", 
        });
           
            
            event.preventDefault();
        }
    }, { passive: false });
});

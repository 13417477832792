uniform vec3 uColor;
varying vec2 vUv;

varying float vWobble;

uniform vec3 uColorA;
uniform vec3 uColorB;

void main()
{
    // Calculate the mix factor for color interpolation based on vWobble
    float colorMix = smoothstep(-1.0, 1.0, vWobble);    

    // Interpolate between uColorA and uColorB based on the mix factor
    csm_DiffuseColor.rgb = mix(uColorA, uColorB, colorMix);

    // Optional: visualize the wobble value for debugging
    // csm_FragColor.rgb = vec3(vWobble);

    // Optional: set a uniform color for testing
    // csm_DiffuseColor.rgb = uColor;

    // Optional: visualize UV coordinates for debugging
    // csm_DiffuseColor.rgb = vec3(vUv, 1.0);

    // Optional: use UV coordinates to set metalness and roughness for testing
    // csm_Metalness = step(0.0, sin(vUv.x * 100.0));
    // csm_Roughness = 1.0 - csm_Metalness;
}
